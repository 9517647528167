var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header', {
    attrs: {
      "header-data": _vm.profileData.header
    }
  })])], 2), _c('div', {
    staticClass: "container-fluid",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'home'
      }
    }
  }, [_vm._v(" Beranda ")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Paket Try Out Selengkapnya ")])], 1)], 1)]), _c('div', {
    staticClass: "container-fluid",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row row-cols-1 row-cols-md-4 g-3"
  }, _vm._l(_vm.data, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col mb-4",
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('div', {
      staticClass: "card mx-auto shadow-lg rounded-3",
      staticStyle: {
        "width": "100%",
        "max-width": "20rem",
        "transition": "0.3s"
      }
    }, [_c('div', {
      staticClass: "position-relative overflow-hidden rounded-top-3"
    }, [_c('img', {
      staticClass: "card-img-top",
      staticStyle: {
        "height": "200px",
        "object-fit": "cover"
      },
      attrs: {
        "src": "https://api-ecourse.digma.id/".concat(item.thumbnail),
        "alt": "City Lights In Newyork"
      }
    })]), _c('div', {
      staticClass: "card-body"
    }, [_c('div', {
      staticClass: "d-flex mb-2",
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_c('span', {
      staticClass: "text-black"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('i', {
      staticClass: "fa-regular fa-user text-black"
    }), _c('span', {
      staticClass: "text-black ms-1",
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v("150")]), _c('i', {
      staticClass: "fa-regular fa-clock text-black",
      staticStyle: {
        "margin-left": "20px"
      }
    }), _c('span', {
      staticClass: "text-black",
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v("40m")])]), _c('hr'), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "text-black me-2",
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("Rp " + _vm._s(item.price.toLocaleString('id-ID')))]), _c('span', {
      staticClass: "ml-auto"
    }, [_c('button', {
      staticClass: "btn btn-outline-primary btn-sm",
      attrs: {
        "type": "button"
      }
    }, [_vm._v("Beli")])])])])])]);
  }), 0)])]), _c('footer-home')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }